/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { jsx } from 'theme-ui';
import React from 'react';
import ScheduleForm from '../../components/IntroSection/ScheduleForm';
import Text_calltoaction from '../../components/raw_templates/Text_calltoaction';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing';
import { graphql, Link } from 'gatsby';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Text_Image from '../../components/raw_templates/Text_Image';

const Terminbuchung = ({ location, data }) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (
          <div sx={{ position: 'relative' }}>
            <Link
              sx={{
                position: 'sticky',
                float: 'right',
                top: 0,
                zIndex: 3,
                color: 'primary',
              }}
              to={closeTo}
            >
              <i className="fad fa-window-close fa-2x"></i>
            </Link>
            <Content location={location} hero={data.hero} />
          </div>
        ) : (
          <HomePageLayout
            location={location}
            lang="de"
            title="Jetzt persönlichen Termin vereinbaren!"
            description="In einem persönlichen Gespräch lässt sich vieles ganz einfach klären. Unsere regionalen Immobilienexperten sind für Sie da und freuen sich darauf, Sie beim Hausverkauf unterstützen zu dürfen!"
            image={data.hero.childImageSharp.fluid}
            keywords={['Terminbuchung']}
            header={{
              attachment: 'default',
              appearance: 'default',
              fluid: true,
            }}
          >
            <Content location={location} hero={data.hero} />
          </HomePageLayout>
        )}
      </div>
    )}
  </ModalRoutingContext.Consumer>
);

const Content = ({ hero, location }) => (
  <>
    {/*<HeroSectionContainer*/}
    {/*  fluid={false}*/}
    {/*  orientation="southEast"*/}
    {/*  variant="alpha"*/}
    {/*  title="Termin vereinbaren"*/}
    {/*  image={hero}*/}
    {/*/>*/}
    <Text_Image
      background="white"
      image={hero}
      title="Vereinbaren Sie jetzt einen unverbindlichen Termin zum Ankauf Ihres Hauses!"
      text="<p>In einem persönlichen Beratungsgespräch lässt sich vieles ganz einfach klären. Unsere regionalen Immobilienexperten sind gerne für Sie da und beantworten alle Fragen, wenn Sie Ihre Immobilie schnell und einfach verkaufen möchten.</p>
<p>Buchen Sie jetzt Ihren persönlichen Beratungstermin – natürlich kostenfrei und unverbindlich!</p>"
    />
    <ScheduleForm location={location} />
  </>
);

export default Terminbuchung;

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-terminvereinbaren" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
